import { graphql } from "gatsby";
import React, {
  Fragment,
  createElement,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { dirname } from "path";
import {
  Box,
  Button,
  Image,
  Fade,
  Flex,
  Divider,
  Heading,
  IconButton,
  ListItem,
  OrderedList,
  UnorderedList,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  Thead,
  Tooltip,
  chakra,
  useToken,
} from "@chakra-ui/react";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { FiChevronsRight } from "react-icons/fi";
import { MDXProvider } from "@mdx-js/react";
import { YouTube } from "mdx-embed";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { PopUp } from "../components/PopUp";
import Blockquote from "../components/Blockquote";
import CodeBlock from "../components/CodeBlock";
import CodeColumns from "../components/CodeColumns";
import RelativeLink, { ButtonLink } from "../components/RelativeLink";
import TableOfContents from "../components/TableOfContents";
import MultiCodeBlock, {
  MultiCodeBlockContext,
} from "../components/MultiCodeBlock";
import { PathContext } from "../utils";
import Header, { TOTAL_HEADER_HEIGHT } from "../components/Header";
import MobileNav from "../components/MobileNav";
import DocsetMenu from "../components/DocsetMenu";
import Pagination from "../components/Pagination";
import InlineCode from "../components/InlineCode";
import Gallery from "../components/Gallery";
import ExpansionPanel, {
  ExpansionPanelList,
  ExpansionPanelListItem,
} from "../components/ExpansionPanel";
import Footer from "../components/Footer";
import Sidebar, {
  SIDEBAR_WIDTH_BASE,
  SIDEBAR_WIDTH_XL,
  SidebarNav,
} from "../components/Sidebar";
import FlowChart, { FlowChartItem } from "../components/FlowChart";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { rehype } from "rehype";
import rehypeReact from "rehype-react";
import autolinkHeadings from "rehype-autolink-headings";
import { useMediaQuery } from "@chakra-ui/react";

import { motion } from "framer-motion";

const LIST_SPACING = 4;
const HEADINGS = ["h1", "h2", "h3", "h4", "h5", "h6"];

const NESTED_LIST_STYLES = {
  [["ul", "ol"]]: {
    mt: 3,
    fontSize: "md",
    lineHeight: "normal",
  },
};

const components = {
  h1: (props) => <Heading as="h1" size="2xl" {...props} />,
  h2: (props) => <Heading as="h2" size="xl" {...props} />,
  h3: (props) => <Heading as="h3" size="lg" {...props} />,
  h4: (props) => <Heading as="h4" size="md" {...props} />,
  h5: (props) => <Heading as="h5" size="sm" {...props} />,
  h6: (props) => <Heading as="h6" size="xs" {...props} />,
  ul: (props) => (
    <UnorderedList
      spacing={LIST_SPACING}
      sx={{
        ...NESTED_LIST_STYLES,
        ul: {
          listStyleType: "circle",
        },
      }}
      {...props}
    />
  ),
  ol: (props) => (
    <OrderedList
      spacing={LIST_SPACING}
      sx={NESTED_LIST_STYLES}
      {...props}
    />
  ),
  li: (props) => (
    <ListItem
      sx={{
        ">": {
          ":not(:last-child)": {
            mb: 3,
          },
        },
      }}
      {...props}
    />
  ),
  p: Text,
  a: RelativeLink,
  pre: CodeBlock,
  table: Table,
  thread: Thead,
  tbody: Tbody,
  tr: Tr,
  th: Th,
  td: (props) => (
    <Td
      sx={{
        fontSize: "md",
      }}
      {...props}
    />
  ),
  blockquote: Blockquote,
  image: Image,
  undefined: Fragment,
};

const mdxComponents = {
  ...components,
  MultiCodeBlock,
  inlineCode: InlineCode,
  Button,
  ExpansionPanel,
  ExpansionPanelList,
  ExpansionPanelListItem,
  YouTube,
  CodeColumns,
  ButtonLink,
  Gallery,
  Image,
  PopUp,
  FlowChart,
  FlowChartItem,
};

const { processSync } = rehype()
  .data("settings", { fragment: true })
  .use(autolinkHeadings, { behavior: "wrap" })
  .use(rehypeReact, {
    createElement,
    Fragment,
    components: {
      ...components,
      code: InlineCode,
    },
  });

export default function PageTemplate({
  data,
  pageContext,
  location: { pathname: uri },
}) {
  const paddingTop = useToken("space", 10);
  const paddingBottom = useToken("space", 12);
  const scrollMarginTop = useMemo(
    () => `calc(${paddingTop} + ${TOTAL_HEADER_HEIGHT}px)`,
    [paddingTop]
  );

  const [language, setLanguage] = useLocalStorage("language");
  const [sidebarHidden, setSidebarHidden] = useLocalStorage(
    "sidebar"
  );

  const { siteUrl } = data.site.siteMetadata;
  const {
    name,
    childMdx,
    childrenMarkdownRemark,
    basePath,
  } = data.file;

  const childMarkdownRemark = childrenMarkdownRemark.length
    ? childrenMarkdownRemark[0]
    : {};
  const { frontmatter, headings } = childMdx || childMarkdownRemark;
  const { title, description, toc } = frontmatter;
  const { docset, docsets, navItems } = pageContext;

  // const landingPage = docsets.find((element) => element.route === "/");

  var variation = false;

  if (
    title === "Bank specific provisions" ||
    title === "User agreement" ||
    title === "Card specific provisions" ||
    title === "Acceptable use policy"
  ) {
    variation = true;
  }

  var expandWidth = false;
  if (
    title === "JS SDK" ||
    title === "Node JS SDK" ||
    title === "React SDK" ||
    title === "React Native SDK" ||
    title === "PHP SDK" ||
    title === "Python SDK" ||
    title === "Save Card"
  ) {
    expandWidth = true;
  }

  const renderSwitcher = useCallback(
    (props) => <DocsetMenu docset={docset} {...props} />,
    [docset]
  );
  const [width, setWidth] = useState(
    `calc(100vw - ${SIDEBAR_WIDTH_BASE}px)`
  );

  const [isBiggerThan768] = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    if (sidebarHidden) {
      setWidth("100%");
    } else {
      setWidth(`calc(100vw - ${SIDEBAR_WIDTH_BASE}px)`);
    }
  }, [sidebarHidden]);

  useEffect(() => {
    if (docset === "Home") {
      setSidebarHidden(true);
    } else {
      setSidebarHidden(false);
    }
  }, [docset]);

  return (
    <>
      <GatsbySeo
        title={title}
        description={description}
        canonical={siteUrl + uri}
        openGraph={{
          title,
          description,
        }}
      />
      <PathContext.Provider
        value={{
          uri,
          basePath,
          path: name === "index" ? uri : dirname(uri),
        }}
      >
        <Header docsets={docsets}>
          <MobileNav>
            <SidebarNav navItems={navItems} darkBg="gray.700">
              <Box px="3" pt="1" pb="3">
                {renderSwitcher({ size: "sm" })}
              </Box>
            </SidebarNav>
          </MobileNav>
          {renderSwitcher({ d: { base: "none", md: "flex" } })}
        </Header>

        {docset !== "Home" && (
          <Fade in={sidebarHidden} unmountOnExit delay={0.25}>
            <Tooltip placement="right" label="Show sidebar">
              <IconButton
                d={{ base: "none", md: "flex" }}
                pos="fixed"
                mt="2"
                left="2"
                size="sm"
                variant="outline"
                fontSize="md"
                icon={<FiChevronsRight />}
                css={{ top: TOTAL_HEADER_HEIGHT }}
                onClick={() => setSidebarHidden(false)}
              />
            </Tooltip>
          </Fade>
        )}
        <Sidebar isHidden={sidebarHidden}>
          <SidebarNav
            navItems={navItems}
            onHide={() => {
              setSidebarHidden(true);
            }}
          />
        </Sidebar>
        <Box>
          <motion.div
            initial={{
              width: isBiggerThan768
                ? `calc(100vw - ${SIDEBAR_WIDTH_BASE}px)`
                : "100%",
              marginLeft: isBiggerThan768
                ? SIDEBAR_WIDTH_BASE + 10
                : 0,

              // base: {
              //   width: "100%",
              //   marginLeft: 0,
              // },
              // md: {
              //   width: `calc(100vw - ${SIDEBAR_WIDTH_BASE}px)`,
              //   marginLeft: SIDEBAR_WIDTH_BASE + 10,
              // },
            }}
            animate={{
              // base: {
              //   width: "100%",
              //   marginLeft: 0,
              // },
              // md: {
              //   width: width,
              //   marginLeft: sidebarHidden
              //     ? 0
              //     : SIDEBAR_WIDTH_BASE + 10,
              // },

              width: isBiggerThan768 ? width : "100%",
              marginLeft: isBiggerThan768
                ? sidebarHidden
                  ? 0
                  : SIDEBAR_WIDTH_BASE + 10
                : 0,
            }}
            transition={{ width: { duration: 0.4 } }}
          >
            <Flex
              maxW={expandWidth ? "9xl" : "6xl"}
              pl={sidebarHidden ? 20 : 10}
              pr={10}
              mx="auto"
              align="flex-start"
              as="main"
              sx={{
                paddingTop,
                paddingBottom,
              }}
            >
              <Box flexGrow="1" w="0">
                <Heading as="h1" size="2xl">
                  {title}
                </Heading>
                {description && (
                  <chakra.h2
                    fontSize={{ base: "xl", md: "2xl" }}
                    lineHeight="normal"
                    mt={{ base: 2, md: 3 }}
                    fontWeight="normal"
                  >
                    {description}
                  </chakra.h2>
                )}
                <Divider my="8" />
                <Box
                  fontSize={{ md: "lg" }}
                  lineHeight={{ md: 1.7 }}
                  css={{
                    [HEADINGS]: {
                      scrollMarginTop,
                    },
                  }}
                  sx={{
                    [HEADINGS]: {
                      a: {
                        color: "inherit",
                      },
                      code: {
                        bg: "none",
                        p: 0,
                        color: "secondary",
                      },
                    },
                    ">": {
                      ":not(:last-child)": {
                        mb: 6,
                      },
                      [HEADINGS]: {
                        ":not(:first-child)": {
                          mt: 10,
                          mb: 4,
                        },
                      },
                    },
                    "img.screenshot": {
                      shadow: "md",
                      rounded: "md",
                    },

                    ".sticky-table": {
                      rounded: "md",
                      overflow: "auto",
                      shadow: "inner",
                      borderWidth: 1,
                      table: {
                        borderWidth: 0,
                        [["td", "th"]]: {
                          ":first-of-type": {
                            position: "sticky",
                            left: 0,
                            bg: "bg",
                            borderRightWidth: 1,
                          },
                        },
                        "tr:last-child": {
                          td: {
                            borderBottom: "none",
                          },
                        },
                      },
                    },
                  }}
                >
                  <MultiCodeBlockContext.Provider
                    value={{ language, setLanguage }}
                  >
                    {childMdx ? (
                      <MDXProvider components={mdxComponents}>
                        <MDXRenderer>{childMdx.body}</MDXRenderer>
                      </MDXProvider>
                    ) : (
                      processSync(childMarkdownRemark.html).result
                    )}
                  </MultiCodeBlockContext.Provider>
                </Box>
                <Pagination navItems={navItems} />
              </Box>
              {toc !== false && (
                // hide the table of contents on the home page
                <chakra.aside
                  d={{ base: "none", lg: "flex" }}
                  flexDirection="column"
                  ml={{ base: 10, xl: 16 }}
                  w={250}
                  flexShrink="0"
                  pos="sticky"
                  top={scrollMarginTop}
                  maxH={`calc(100vh - ${scrollMarginTop} - ${paddingBottom})`}
                >
                  <Heading size="md" mb="3">
                    {title}
                  </Heading>
                  <TableOfContents
                    headings={headings}
                    variation={variation}
                  />
                </chakra.aside>
              )}
            </Flex>
            <Footer />
          </motion.div>
        </Box>
      </PathContext.Provider>
    </>
  );
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query GetPage($id: String) {
    site {
      id
      siteMetadata {
        siteUrl
      }
    }
    file(id: { eq: $id }) {
      name
      relativePath
      base
      basePath: sourceInstanceName
      sourceInstanceName
      childMdx {
        body
        headings {
          depth
          value
        }
        frontmatter {
          title
          description
          toc
        }
      }
      childrenMarkdownRemark {
        html
        htmlAst
        headings {
          depth
          value
        }
        frontmatter {
          title
          description
        }
      }
    }
  }
`;
