import React from 'react'
import {
  Box,
  Flex,
  Text,
  Heading
} from '@chakra-ui/react'

export function FlowChartItem({title, styles={}, indented=false, icon, img, children }) {
  return (
    <Box>
      <Flex 
        paddingBottom='8'
        flexBasis="100%"
        flexDirection="row"
        alignItems="center"
        style={{
          backgroundImage: "linear-gradient(to top, #c1c9d2 0%, #c1c9d2 20%, transparent 20%)",
          backgroundPosition: "left 0",
          backgroundSize: "2px 10px",
          backgroundRepeat: "repeat-y",
          position: "relative",
          ...styles
        }}
      >
        <Flex position="relative" paddingLeft="8" flexGrow="2">
          { icon }
          <Box marginLeft={indented ?'16' : '0'} marginRight="8">
            <Heading size="md">{title}</Heading>
            <Text pt="3">
              { children }
            </Text>
          </Box>
        </Flex>
        <Box paddingY="4">
          { img }
        </Box>
      </Flex>
    </Box>
  )
}

export default function FlowChart({ children }) {
  return (
    <Box marginLeft={'4'} marginTop={'8'}>
      <Flex flexWrap='nowrap' justifyContent="flex-start" flexDirection="column">
        { children }
      </Flex>
    </Box>
  )
}