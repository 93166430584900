import React from "react";
import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";

export default function Blockquote({ intent = "primary", children, last }) {
  switch (intent) {
    case "primary":
      return <Primary>{children}</Primary>;
    case "warn":
      return <Warn>{children}</Warn>;
    case "error":
      return <Error>{children}</Error>;
    case "code-block":
      return <CodeBlock last={last}>{children}</CodeBlock>;
    default:
      return Primary;
  }
}

Blockquote.propTypes = {
  intent: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function Primary(props) {
  return (
    <Box
      pl="4"
      py="1"
      borderLeftWidth="4px"
      borderRadius="4px"
      backgroundColor="bg_primary"
      borderColor="primary"
      fontSize="md"
      sx={{
        ">": {
          ":not(:last-child)": {
            mb: 2,
          },
        },
      }}
      {...props}
    />
  );
}

function Warn(props) {
  return (
    <Box
      pl="4"
      py="1"
      borderLeftWidth="4px"
      borderRadius="4px"
      backgroundColor="bg_warn"
      borderColor="warn"
      fontSize="md"
      sx={{
        ">": {
          ":not(:last-child)": {
            mb: 2,
          },
        },
      }}
      {...props}
    />
  );
}

function Error(props) {
  return (
    <Box
      pl="4"
      py="1"
      borderLeftWidth="4px"
      borderRadius="4px"
      backgroundColor="bg_error"
      borderColor="error"
      sx={{
        ">": {
          ":not(:last-child)": {
            mb: 2,
          },
        },
      }}
      {...props}
    />
  );
}
function CodeBlock(props) {
  return (
    <Box
      pl="4"
      py="1"
      borderLeftWidth="4px"
      borderRadius="4px"
      borderBottomWidth={"2px"}
      backgroundColor="bg"
      borderColor="gray.200"
      fontSize="md"
      sx={{
        ">": {
          ":not(:last-child)": {
            mb: 2,
          },
        },
      }}
      {...props}
    />
  );
}
