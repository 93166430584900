import React from 'react';
import PropTypes from 'prop-types';
import {SimpleGrid} from '@chakra-ui/react';

export default function Gallery({children}) {
  return (
    <SimpleGrid minChildWidth='200px' spacing='40px'>
      {children}
    </SimpleGrid>
  );
}

Gallery.propTypes = {
  children: PropTypes.node.isRequired
};
