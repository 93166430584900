
export const companyCategory = {
  title: 'Company',
  links: [
    {
      text: 'About Us',
      href: 'https://getsafepay.pk/about-us'
    },
    {
      text: 'Careers',
      href: 'https://getsafepay.pk/careers'
    },
    {
      text: 'Customers',
      href: 'https://getsafepay.pk/customers'
    },
    {
      text: 'Contact us',
      href: 'https://getsafepay.pk/contact'
    }
  ]
};

export const productCategory = {
  title: 'Products',
  links: [
    {
      text: 'Checkout',
      href: 'https://getsafepay.pk/checkout-2'
    },
    {
      text: 'Quick Links',
      href: 'https://getsafepay.pk/quick-links'
    },
    {
      text: 'Invoices',
      href: 'https://getsafepay.pk/invoices'
    },
    {
      text: 'Features',
      href: 'https://getsafepay.pk/features'
    }
  ]
};

export const whySafepayCategory = {
  title: 'Why Safepay?',
  links: [
    {
      text: 'Benefits',
      href: 'https://getsafepay.pk/why-safepay'
    },
    {
      text: 'How Safepay works',
      href: 'https://getsafepay.pk/products#how-safepay-works'
    },
    {
      text: 'How to start',
      href: 'https://getsafepay.pk/products#how-to-start'
    }
  ]
};

export const resourcesCategory = {
  title: 'Resources',
  links: [
    {
      text: 'Knowledge center',
      href: 'https://safepay.helpscoutdocs.com/'
    },
    {
      text: 'Blog',
      href: 'https://getsafepay.pk/blog'
    },
    {
      text: 'Media kit',
      href: 'https://getsafepay.pk/media-kit'
    },
    {
      text: 'Terms of service and use',
      href: 'https://getsafepay.pk/terms-of-service-and-use'
    },
    {
      text: 'Acceptable use policy',
      href: 'https://safepay.helpscoutdocs.com/article/69-acceptable-use-policy#introduction'
    },
    {
      text: 'Privacy policy',
      href: 'https://safepay.helpscoutdocs.com/article/25-privacy-policy'
    },
    {
      text: 'KYC policy',
      href: 'https://safepay.helpscoutdocs.com/article/73-know-your-customer-kyc-policy'
    }
  ]
};

export const footerConfig = [
  companyCategory,
  resourcesCategory,
  productCategory,
  whySafepayCategory
];
