import React from 'react'
import SafepayLogoDark from '../../assets/svg/safepay-logo-dark.svg'
import SafepayLogoLight from '../../assets/svg/safepay-logo-white.svg'
import {
  Flex,
  Heading,
  Link,
  Image,
  List,
  ListItem,
  SimpleGrid,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import {footerConfig} from './config';

export default function Footer() {
  const LogoIcon = useColorModeValue(SafepayLogoDark, SafepayLogoLight)
  return (
    <SimpleGrid
      as="footer"
      columns={[1, 2, 3, 5]}
      spacing={{base: 6, md: 8}}
      borderTopWidth="1px"
      px="10"
      py="12"
    >
      <div>
        <Flex mb="2">
          <a href='https://getsafepay.pk/'>
            <Image
              w={'24'} 
              src={LogoIcon} 
              alt="Safepay logo"
              aria-label='Safepay logo'
              mt="0.5"
            />
          </a>
        </Flex>
        <Text>&copy; Safepay 2024</Text>
      </div>
      {footerConfig.map(({links, title}, index) => (
        <div key={index}>
          <Heading mb="2" size="md">
            {title}
          </Heading>
          <List spacing="1">
            {links.map(({href, text}, index) => (
              <ListItem key={index}>
                <Link href={href}>{text}</Link>
              </ListItem>
            ))}
          </List>
        </div>
      ))}
    </SimpleGrid>
  )
}